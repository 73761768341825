import React from "react";
import Aux from "../../hoc/Auxiliary";
import Logo from "../Logo/Logo";
import NavigationItems from "./NavigationItems/NavigationItems";
import SocialMedia from "../SocialMedia/SocialMedia";
import classes from './Navbar.module.css'
import SiteName from "../Logo/SiteName/SiteName";


const navbar = (props) =>{
    return(
        <Aux>
            <div className={classes.Navbar}>
                <Logo/>
                <SiteName/>
                
                <NavigationItems/>  
                <SocialMedia/>
                
            </div>
        </Aux>
    )
}
export default navbar