import React from "react";
import NavigationItem from "./NavigationItem/NavigationItem";
import classes from './NavigationItems.module.css'
const navigationItems = ()=>{
    return(
        <div>
            <ul  className={classes.NavigationItems}>
                <NavigationItem path ='/' >HOME</NavigationItem>
                <NavigationItem path ='/projects' >PROJECTS</NavigationItem>
                <NavigationItem path ='/about'>ABOUT US</NavigationItem>
                <NavigationItem path ='/contact'>CONTACT</NavigationItem>

            </ul>
        </div>
    )
}
export default navigationItems