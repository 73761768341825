import React from "react";
import classes from './Project.module.css'

const project=(props)=>{
    return(
       
            <img src={props.imgUrl}  alt= '' className={classes.Project}
            onClick={props.clicked}/>
      
    )
    
}
export default project