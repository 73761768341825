import React from "react";
import classes from './SocialMedia.module.css';
import xicoon from '../../Images/Xtwitter.png';
import instaicon from '../../Images/Instagram.png';
import paintersticoon from '../../Images/Painterst.png';

const SocialMedia = () => {
    return (
        <div className={classes.SocialMedia}>
            <a href="https://www.instagram.com/xarchitz/">
                <div className={classes.IconClasses}><img src={instaicon} alt="Instagram"/></div>
            </a>
            <a href="https://twitter.com/xarchitz">
                <div className={classes.IconClasses}><img src={xicoon} alt="Twitter"/></div>
            </a>
            <a href="https://www.pinterest.com/xarchitz/">
                <div className={classes.IconClasses}><img src={paintersticoon} alt="Pinterest"/></div>
            </a>
        </div>
    );
};

export default SocialMedia;
