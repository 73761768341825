import React from "react";
import classes from './Logo.module.css'
import logoimg from '../../Images/xarchitzlogo.png'
const logo =()=>{
    return(
        <div className={classes.LogoContainer}>
        <img src={logoimg}  alt = '' className={classes.Logo} />
        
        </div>
    )
    
}
export default logo ; 