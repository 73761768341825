import React from "react";
import classes from './Backdrop.module.css'
const backdrop =(props)=>{
    const showBackdropClasses = `${classes.Backdrop} ${props.showBackdrop  ? classes.BackdropShow : ''}`;
    return(
        
        
        <div className={showBackdropClasses} onClick={props.clicked}></div>
    )
}
export default backdrop