import React, { Component } from "react";
import classes from './Slider.module.css';
import { storageRef } from "../../Firebase/Config/Firebase";
import { listAll, getDownloadURL } from "firebase/storage";
import Spinner from "../../Components/UI/Spinner/Spinner";
import Aux from "../../hoc/Auxiliary";
import { ArrowLeftCircle ,ArrowRightCircle } from "lucide-react"; 

class Slider extends Component {
    state = {
        currentIndex: 0,
        imgUrls: [],
        loading : true
    }

    changeSlide = () => {
        const { currentIndex, imgUrls } = this.state;
        let nextIndex = currentIndex + 1;

        if (nextIndex >= imgUrls.length) {
            nextIndex = 0;
        }

        this.setState({
            currentIndex: nextIndex
        });
    }
    showNextImage=()=>{
        let currentIndexTemp = this.state.currentIndex 
        if(currentIndexTemp === this.state.imgUrls.length - 1){
            currentIndexTemp = 0
           return this.setState({currentIndex: currentIndexTemp}) 
        }else{
        currentIndexTemp = this.state.currentIndex + 1 
        return this.setState({currentIndex: currentIndexTemp})}

    }
    showPrevImage=()=>{
        let currentIndexTemp = this.state.currentIndex 
        if(currentIndexTemp === 0){
            currentIndexTemp = this.state.imgUrls.length - 1
           return this.setState({currentIndex: currentIndexTemp})
        }else{
        currentIndexTemp = this.state.currentIndex - 1 
        return this.setState({currentIndex: currentIndexTemp})}

    }

    componentDidMount() {
        this.interval = setInterval(this.changeSlide, 5000);

        listAll(storageRef)
            .then((result) => {
                const urlPromises = result.items.map((imageRef) =>
                    getDownloadURL(imageRef)
                );

                Promise.all(urlPromises)
                    .then((urls) => {
                        this.setState({ imgUrls: urls, loading : false});

                    })
                    .catch((error) => {
                        console.error('Error fetching download URLs:', error);
                    });
            })
            .catch((error) => {
                console.error('Error listing files:', error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { currentIndex, imgUrls } = this.state;
    
        let itLoad = <Spinner />;
        if (!this.state.loading) {
            itLoad = (
                <Aux>
                    <div className= {classes.SliderContainer}>
                        <div className={classes.SliderImages} style={{ transform: `translateX(-${100 * currentIndex}%)` }}>
                            {imgUrls.map((imageUrl, index) => (
                                <img
                                    src={imageUrl}
                                    alt=''
                                    key={imageUrl}
                                    className={classes.Slider}
                                />
                            ))}
                        </div>
    
                        <button className={classes.Arrows}
                            style={{ left: 0 }}
                            onClick={this.showPrevImage}>
                            <ArrowLeftCircle />
                        </button>
    
                        <button className={classes.Arrows}
                            style={{ right: 0 }}
                            onClick={this.showNextImage}>
                            <ArrowRightCircle />
                        </button>
                    </div>
                </Aux>
            );
        }
    
        return (
            <Aux>
                {itLoad}
            </Aux>
        );
    }
}

export default Slider;
