import React from "react";
import classes from './Contact.module.css'

import Contactpost from "../../Components/Post/ContactPost/ContactPost";


const contact = (props) =>{
    return(
        <div className={classes.Contact}>
       
            <Contactpost/>
                 

        </div>
    )
}
export default contact