


import Layout from './Containers/Layout/Layout'
import classes from './App.module.css'
import { Route , Routes} from 'react-router-dom';
import Projects from './Containers/Projects/Projects';
import Aux from './hoc/Auxiliary';
import Home from './Containers/Home/Home';
import About from './Containers/About/About';
import Contact from './Containers/Contact/Contact';




function App() {
  return (
    <Aux>
      <div className={classes.App}>
        <Layout/>
        <Routes>
          <Route path='*' element ={<Home/>} />
          <Route path='/projects/' element ={<Projects/>} /> 
          <Route path='/about' element ={<About/>} />   
          <Route path='/contact' element ={<Contact/>} />   
        </Routes>
        
      </div>
      
    
    </Aux>
  );
}

export default App;
