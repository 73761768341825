import React from "react";
import classes from './ImagePrev.module.css'

const imagePrev=(props)=>{
    return (
        <div className={classes.ImagePrev}>
            <img src={props.imgLink} alt = ''/>
        </div>
    )
}
export default imagePrev