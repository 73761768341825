import React from "react";
import classes from './About.module.css'
import Post from "../../Components/Post/Post";
const about = (props) =>{
    const textest = "I am a 3D designer with an interest in crafting and showcasing intricate 3D objects and immersive environments. Leveraging my expertise in various 3D programs such as 3ds Max, Blender, and SketchUp, I bring creativity and precision to every project, ensuring stunning visual outcomes."
    const textest2 ="With a Bachelor's degree in Architecture Engineering, I am well-versed in architectural principles and possess a deep understanding of design workflows. expert in tools like AutoCAD and Revit, I adeptly translate architectural concepts into detailed plans and models, ensuring the seamless realization of architectural visions."
    const textest3 ="I have proficiency in 3D animation using software like Blender and 3ds Max. Additionally, I am skilled in front-end web development for creating user-friendly interfaces. I also have an interest in game development engines such as Unity and Unreal. Moreover, I'm experienced in graphic design tasks including logo design and post-production using Photoshop and Illustrator."
    return(
        <div className={classes.About}>
            <Post textest ={textest}
                imgLink="https://images.squarespace-cdn.com/content/v1/5176fdb5e4b083b631f31303/1366835271263-HY9F05W228BCVB9H6E2D/bigstock_cube_842946.jpg"
            />

            <Post textest ={textest2}
                imgLink ="https://sdaarchitects.com/wp-content/uploads/2020/11/shutterstock_246019015.jpg"
            />
            <Post textest ={textest3}
                imgLink ="https://mathgrrl.com/wp-content/uploads/2017/03/0-cover-multiple-programs-1.jpg"
            />
            

        </div>
    )
}
export default about