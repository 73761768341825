import React from "react";
import classes from './Home.module.css'
import Slider from "../Slider/Slider";
import Projects from "../Projects/Projects";
const home = ()=>{
    return(
        <div className={classes.Home}>
            <Slider />
            <Projects removeOverflow = {true}/>
        </div>

    )

}
export default home