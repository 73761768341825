import React from "react";
import classes from './Post.module.css'

const post =(props)=>{
    return(
        <div className={classes.PostContainer}>
            <div className={classes.TextContainer}>
                <p>{props.textest}</p>
            </div>
            <div className={classes.ImageContainer}>
                <img src={props.imgLink} alt=''/>
            </div>

        </div>
    )
}
export default post