import React, {Component} from "react";

import Navbar from "../../Components/Navbar/Navbar";
import classes from './Layout.module.css'
import Logo from "../../Components/Logo/Logo";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Backdrop from "../../Components/UI/Backdrop/Backdrop"

class Layout extends Component {

    state={
        showSidebar : false
    }
    onSwitchSidebarHandler=()=>{
        const showSidebarTemp = this.state.showSidebar;
        this.setState({showSidebar:!showSidebarTemp})
    }

    render(){
        return(
            <div className={classes.Layout} >
                <div className={classes.LogoClicked} 
                onClick={this.onSwitchSidebarHandler}
                > <Logo/></div>
                <Backdrop showBackdrop={this.state.showSidebar} clicked={this.onSwitchSidebarHandler}/>
                <Sidebar showSidebar={this.state.showSidebar}/>
                <Navbar/>
                
            </div>
        )
    }
}
export default Layout