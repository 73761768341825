import React, {Component} from "react";
import { storageRefExt } from "../../Firebase/Config/Firebase";
import { storageRefInt } from "../../Firebase/Config/Firebase";
import { listAll, getDownloadURL } from "firebase/storage"
import Project from "../../Components/Project/Project";
import classes from './Projects.module.css'
import Spinner from "../../Components/UI/Spinner/Spinner";
import Aux from "../../hoc/Auxiliary";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import ImagePrev from "../../Components/UI/ImagePrev/ImagePrev";
import Masonry from 'react-masonry-css'






class Projects extends Component{

    state = {
        projectContainer:[],
        loading : true,
        showBackdrop: false ,
        imagePrevState : '',
        
        
    }
    showPreview =(url)=>{
        const showBackdropTemp = !this.state.showBackdrop
        const imagePrevStateTemp = url
        this.setState({showBackdrop :  showBackdropTemp , imagePrevState : imagePrevStateTemp})
    }
    disaplePreview =()=>{
        const showBackdropTemp = !this.state.showBackdrop
        const imagePrevStateTemp = ''
        this.setState({showBackdrop :  showBackdropTemp , imagePrevState : imagePrevStateTemp})


    }

    componentDidMount(){

        Promise.all([listAll(storageRefExt), listAll(storageRefInt)])
        .then((results)=>{
            const urlsPromise = results.flatMap(result=>
                result.items.map(url=> getDownloadURL(url)))

                Promise.all(urlsPromise).then((urls)=>(
                    this.setState({projectContainer:urls , loading : false})
                )).catch((error) => {
                    console.error('Error fetching download URLs:', error);
                    throw error;
                    
                  });
        }).catch((error) => {
            console.error('Error listing files:', error);
            throw error;
            
          });

    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.projectContainer === prevState.projectContainer){
            return 
        }
    }
    render(){

        
        const breakpointColumnsObj = {
            default: 3,
            1100: 2,
            300: 1,
            
          };
            let itLoad = <Spinner/>
        
        if(!this.state.loading){
            itLoad = 
            

            <Masonry
            breakpointCols={breakpointColumnsObj}
            className={`${classes.mymasonrygrid} ${this.props.removeOverflow? classes.OverflowRemoved : ''}` }
            columnClassName={classes.mymasonrygridcolumn}>
            
                {this.state.projectContainer.map((url, index)=>(
                    <div key={url}>
                    <Project   imgUrl={url} alt="" clicked ={()=>this.showPreview(url)}/>
                    </div>
                ))}

            
            </Masonry>
        }

        return(
            <Aux>

                
                {itLoad}
                
                
                <Backdrop showBackdrop={this.state.showBackdrop} clicked = {this.disaplePreview}/>
                <ImagePrev imgLink = {this.state.imagePrevState} />
      

                        
 
               
            </Aux>
        )
    }
}

export default Projects;
