// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import "firebase/compat/storage";
import { getStorage, ref } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTQscP7vqNwHnGEJdWjDdzMpmNRLaBaKU",
  authDomain: "xarchitz-bdea0.firebaseapp.com",
  projectId: "xarchitz-bdea0",
  storageBucket: "xarchitz-bdea0.appspot.com",
  messagingSenderId: "746680686555",
  appId: "1:746680686555:web:c62931a245acc9719ab9fe",
  measurementId: "G-RS86MY6CMT"
};

// Initialize Firebase
 const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
//export const storage = firebase.storage();
const storage = getStorage();
export const storageRef = ref(storage, '/Projects/Hero')
export const storageRefInt = ref(storage, '/Projects/Interior')
export const storageRefExt = ref(storage, '/Projects/Exterior')

