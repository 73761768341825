import React from "react";
import classes from './ContactPost.module.css'
import SocialMedia from "../../SocialMedia/SocialMedia";



const contactpost =(props)=>{
    return(
        <div className={classes.ContactPostContainer}>
            <div className={classes.TextContainer}>
                <p>
                    Thank you for your interest in my portfolio.
                    We value your feedback and inquiries. 
                    Please feel free to reach out to us using the contact information provided below.
                </p>
                <p>
                    Business Information:<br/>
                    Name: Xarchitz<br/>
                    Email: xarchitz@gmail.com<br/>
                </p>
                <p>
                    Alternatively, you can use the contact form below to send us a message. 
                    We will get back to you as soon as possible.
                </p>
                <SocialMedia/>
                <p>
                    Thank you for considering Xarchitz . We look forward to hearing from you!
                </p>




            </div>

        </div>
    )
}
export default contactpost