import React from "react";
import classes from './Sidebar.module.css'
import Logo from "../Logo/Logo";
import NavigationItems from "../Navbar/NavigationItems/NavigationItems";
import SocialMedia from "../SocialMedia/SocialMedia";
import SiteName from "../Logo/SiteName/SiteName";

const sidebar =(props)=>{
    const sidebarClasses = `${classes.Sidebar} ${props.showSidebar ? classes.ShowSidebar : ''}`;
    
    

    return (
        <div className={sidebarClasses} >
        <Logo/>
        <SiteName/>
        <NavigationItems/>
        <SocialMedia/>



        </div>
    )
}
export default sidebar